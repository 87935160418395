import { AjaxInstance } from "../Ajax";

export const UserService = {
  login,
  logout,
  currentUser
};

async function login(email, password) {
  return AjaxInstance.post(
    "?action=user-login&timestamp=" + new Date().getTime(),
    {
      email: email,
      password: password
    }
  ).then(user => {
    console.log(user);
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    }
    return Promise.resolve(user);
  });
}

async function logout() {
  return AjaxInstance.post(
    "?action=user-logout&timestamp=" + new Date().getTime()
  ).then(result => {
    localStorage.removeItem("user");
    return Promise.resolve(result);
  });
}

async function currentUser() {
  return AjaxInstance.post(
    "?action=user-current&timestamp=" + new Date().getTime()
  );
}
