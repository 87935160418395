import React from "react";
import "./util/styles.css";
import BasicApp from "./util/base/BasicApp";
import Login, { LoginRoute } from "./util/login/Login";
import Tile from "./util/display/Tile";
import { AjaxInstance } from "./util/Ajax";
import { useAppContext } from "./util/base/BasicApp";

const homeRoute = {
  path: "/",
  exact: "true",
  private: "false",
  icon: "\uf015",
  title: "Home"
};
const aboutRoute = {
  path: "/about",
  exact: "true",
  private: "true",
  icon: "\uf2bb",
  title: "About me"
};

function SubView() {
  const [state] = useAppContext();
  return (
    <div {...aboutRoute}>
      <Tile title="About me">
        Hey, you are {state.user.email}. Or are you{" "}
        {localStorage.getItem("user")}
      </Tile>
    </div>
  );
}

function App() {
  return (
    <div>
      <BasicApp enableSearch={false}>
        <div {...homeRoute}>
          <Tile title="Welcome">Welcome to the Home content</Tile>
          <button
            onClick={() => {
              AjaxInstance.post("?action=user-current", {}).then(result => {
                console.log(result);
              });
            }}
          >
            Click me{" "}
          </button>
        </div>
        <SubView {...aboutRoute}></SubView>
        <Login {...LoginRoute}></Login>
      </BasicApp>
    </div>
  );
}

export default App;
