export function formatSeconds(
  seconds,
  { hh = true, mm = true, ss = true, sss = true } = {
    hh: true,
    mm: true,
    ss: true,
    sss: true
  }
) {
  var tmp = seconds;
  let hhValue = Math.floor(tmp / 3600);
  tmp = tmp - hhValue * 60;
  let mmValue = Math.floor(tmp / 60);
  tmp = tmp - mmValue * 60;
  let ssValue = Math.floor(tmp);
  let sssValue = Math.round((tmp - ssValue) * 1000);

  var result = hh ? (hhValue + "").padStart(2, "0") : "";
  result += hh && (mm || ss) ? ":" : "";
  result += mm ? (mmValue + "").padStart(2, "0") : "";
  result += mm && ss ? ":" : "";
  result += ss ? (ssValue + "").padStart(2, "0") : "";
  result += (hh || mm || ss) && sss ? "." : "";
  result += sss ? (sssValue + "").padEnd(3, "0") : "";
  return result;
}

export class CustomError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
  }
}

export function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
