import Axios from "axios";
import { CustomError } from "./util";

export const AjaxInstance = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  method: "post",
  timeout: process.env.REACT_APP_AJAX_TIMEOUT,
  headers: { Accept: "application/json", "Content-Type": "application/json" },
  withCredentials: false,
  responseType: "json"
});
AjaxInstance.interceptors.response.use(
  response => {
    return handleResponse(response);
  },
  error => {
    return Promise.reject(error);
  }
);

// returned data is usually in the form {error: true/false, message: "error message", result: data}
export class InvalidReplyError extends CustomError {} // An error that occurs when the result is not in the form described above
export class ApplicationError extends CustomError {} // An error that occurs when the API returns and error
export class AuthenticationError extends CustomError {} // An error that occurs when the server returns 401
export class ServerError extends CustomError {} // An error that occurs when the server returns an error code other than 401

const handleResponse = function(response) {
  switch (response.status) {
    case 401:
      return Promise.reject(new AuthenticationError("Access denied"));
    case 200:
      let data = response.data;

      // Invalid Reply
      if (data === null) {
        return Promise.reject(
          new InvalidReplyError(
            'Expect result in the form {error:true/false, message:"", result: data}.'
          )
        );
      }

      // The data reached the server but something went wrong
      if (data.error) {
        if (data.message.endsWith("Access denied.")) {
          return Promise.reject(new AuthenticationError(data.message));
        }
        return Promise.reject(new ApplicationError(data.message));
      }

      // valid result
      return Promise.resolve(data.result);
    default:
      return ServerError(response.statusText + "(" + response.status + ")");
  }
};
