import React from "react";
import "./ActionBar.css";

export default function ActionBar({ children }) {
  return (
    <div className="action-bar" type="action-bar">
      {children}
    </div>
  );
}
