import React, { useEffect, useRef } from "react";
import "./Textfield.css";
import SaveState from "./SaveState.js";

export default function Textfield({
  value = "",
  placeholder = "",
  label = "",
  type = "text",
  name = "",
  focus = false,

  className = "",
  savingState = null,
  explanation = "",
  valid = true,
  warning = "",
  buttonicon = "",
  onChange = event => {}, // use event.target.value for the field's value
  onSubmit = value => {},
  disabled = false,
  ...rest
}) {
  const inputRef = useRef(null);
  useEffect(() => {
    if (focus && inputRef !== null && inputRef.current !== null) {
      inputRef.current.focus();
    }
    return function cleanup() {};
  }, [focus]);

  return (
    <div
      className={
        className +
        " input" +
        (!valid
          ? " invalid"
          : warning
          ? " warning"
          : disabled
          ? " disabled"
          : "")
      }
    >
      {label ? (
        <div className="inputLabel">
          <label>{label}</label>
        </div>
      ) : null}
      <div className="inputContainer">
        <input
          ref={inputRef}
          name={name}
          disabled={disabled}
          type={type}
          value={value}
          onChange={event => onChange(event)}
          placeholder={placeholder}
          autoFocus={focus}
          onKeyUp={event => {
            if (event.key === "Enter") {
              event.stopPropagation();
              onSubmit(value);
            }
          }}
          {...rest}
        />
        {buttonicon ? (
          <button className="iconbutton" onClick={event => onSubmit(value)}>
            <i className="fas">{buttonicon}</i>
          </button>
        ) : null}
        {savingState == null ? (
          ""
        ) : (
          <SaveState style={{ marginLeft: "-24px" }} state={savingState} />
        )}
      </div>
      {explanation ? <div className="explanation">{explanation}</div> : null}
    </div>
  );
}
