import React from "react";
import "./SaveState.css";

export default function SaveState({ state }) {
  var iconClass = "";
  switch (state) {
    case 0: // failed
      iconClass = "savingFailed";
      break;
    case 1: // success
      iconClass = "savingSuccess";
      break;
    case 2: // notsaved
      iconClass = "notSaved";
      break;
    case 3: // saving
      iconClass = "saving";
      break;
    default:
  }
  return (
    <i
      className={
        "saveState fas  " + iconClass
        /* fas fa-" +
        (state === 1 // success
          ? "check-circle"
          : state === 0 // failed
          ? "times-circle"
          : "spinner")*/
      }
    />
  );
}
